<template>
	<div class="personal">
		<div class="insert-title">个人信息</div>
		<el-form
			:model="formData"
			:rules="rules"
			inline
			:hide-required-asterisk="true"
			ref="personal"
		>
			<el-form-item
				prop="mobile"
				class="pd_l" >
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t5')}}
				</div>
				<div class="open-item-tip" v-show="isShowPhone">{{formData.mobile}}</div>
				<el-input
					:disabled="type == 'update'"
					v-model="formData.mobile"
					type="text"
					:placeholder="$t('open.f25')"
					@input="phoneMustNumber"
				></el-input>
				<p v-if="type == 'insert'" class="tip qjc-fts-12">{{$t('open.f27')}}</p>
			</el-form-item>
			<el-form-item prop="yyuserid" >
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t6')}}
				</div>
				<el-input
					:disabled="type == 'update'"
					v-model="formData.yyuserid"
					type="text"
					:change="check_num()"
					:placeholder="$t('open.f26')"
				></el-input>
				<p v-if="type == 'insert'" class="tip qjc-fts-12">{{$t('open.f27')}}</p>
			</el-form-item>
			<el-form-item
				prop="sex"
				class="pd_l"
			>
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t7')}}
				</div>
				<el-select
					v-model="formData.sex"
					:placeholder="$t('open.f28')"
				>
					<el-option
						:label="$t('open.t14')"
						:value="1"
					></el-option>
					<el-option
						:label="$t('open.t15')"
						:value="2"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				prop="birth"
			>
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t8')}}
				</div>
				<el-date-picker
				  v-model="birthSel"
				  type="date"
				  :placeholder="$t('open.f28')"
				  value-format="yyyy/MM/dd"
				  format="yyyy/MM/dd"
				  @change="handleBirth"
				  :default-value="new Date('1990/1/1')"
				>
				</el-date-picker>
			</el-form-item>
			<!-- <el-form-item
				prop="sex"
				label-width="90px"
				v-if="companyList.length > 0"
			>
				<div class="label qjc-fts-14" slot="label">
					所属企业
				</div>
				<el-select v-model="formData.sex" >
					<el-option :label="item.name" :value="item.id" v-for="item in companyList" :key="item.id"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item
				prop="corp"
				class="pd_l"
				 >
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t9')}}
				</div>
				<el-input
					v-model="formData.corp"
					type="text"
				></el-input>
			</el-form-item>
			<el-form-item
				prop="examdate"
				v-if="!(packageValue == 5 || packageValue == 6)"
			>
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t10')}}
				</div>
				<el-date-picker
				  v-model="examdateSel"
				  type="date"
				  :placeholder="$t('open.f28')"
				  value-format="yyyy/MM/dd"
				  format="yyyy/MM/dd"
				  @change="handleExamdate"
				  :default-value="new Date()"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item
				prop="package"
				class="pd_l"
				style="width: 100%;" >
				<div class="label qjc-fts-14" slot="label">
					{{$t('open.t11')}}
				</div>
				<div class="package" v-for="(goods, goodsIndex) in goodsList">
					 <!-- && goods.goods_id != 5 && goods.goods_id != 6 -->
					<div class="package-radio" v-if="(goods.goods_id != 2) && goods.status == 1">
						<el-radio v-model="packageValue" :disabled="type == 'update' || (pay_mode == 1 && (!goods.times || goods.times <= 0))" :label="goods.goods_id" border>{{goods.name}}</el-radio>
						<div v-if="pay_mode == 1">
							{{$t('table.t19')}}：{{goods.times ? goods.times : '0'}}
						</div>
					</div>
					<div class="package-radio wid-100" v-if="goods.goods_id == 2 && goods.status == 1">
						<div class="custom-box">
							<el-radio v-model="packageValue" :disabled="(type == 'update' && formData.package != '2') || (pay_mode == 1 && ((goods.custom.base && goods.custom.base.times <= 0) || (goods.custom.mlev && goods.custom.mlev.times <= 0)))" label="2">{{goods.name}}</el-radio>
							<div class="custom-list">
								<el-checkbox-group v-model="checkCustomList">
								    <el-checkbox :disabled="true" label="base">{{goods.custom.base && goods.custom.base.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom.base && goods.custom.base.times ? goods.custom.base.times : '0'}}</span></el-checkbox>
								    <el-checkbox :disabled="true" label="mlev">增值包-通用健康评分<span v-if="pay_mode == 1"> (免费)</span></el-checkbox>
								    <el-checkbox :disabled="packageValue != '2' || isBo || (type == 'update' && isSuper != 1) || goods.custom.packageA.status == 0 || (pay_mode == 1 && (goods.custom.packageA && goods.custom.packageA.times <= 0))" label="packageA">{{goods.custom.packageA && goods.custom.packageA.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom.packageA.times ? goods.custom.packageA.times : '0'}}</span></el-checkbox>
								    <el-checkbox :disabled="packageValue != '2' || isBo || (type == 'update' && isSuper != 1) || goods.custom.packageB.status == 0 || (pay_mode == 1 && (goods.custom.packageB && goods.custom.packageB.times <= 0))" label="packageB">{{goods.custom.packageB && goods.custom.packageB.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom && goods.custom.packageB.times ? goods.custom.packageB.times : '0'}}</span></el-checkbox>
								    <el-checkbox :disabled="packageValue != '2' || isBo || (type == 'update' && isSuper != 1) || goods.custom.packageC.status == 0 || (pay_mode == 1 && (goods.custom.packageC && goods.custom.packageC.times <= 0))" label="packageC">{{goods.custom.packageC && goods.custom.packageC.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom && goods.custom.packageC.times ? goods.custom.packageC.times : '0'}}</span></el-checkbox>
								    <el-checkbox :disabled="packageValue != '2' || isBo || (type == 'update' && isSuper != 1) || goods.custom.packageD.status == 0 || (pay_mode == 1 && (goods.custom.packageD && goods.custom.packageD.times <= 0))" label="packageD">{{goods.custom.packageD && goods.custom.packageD.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom && goods.custom.packageD.times ? goods.custom.packageD.times : '0'}}</span></el-checkbox>
								    <el-checkbox :disabled="packageValue != '2' || isBo || (type == 'update' && isSuper != 1) || goods.custom.packageFB.status == 0 || (pay_mode == 1 && (goods.custom.packageFB && goods.custom.packageFB.times <= 0))" label="packageFB">{{goods.custom.packageFB && goods.custom.packageFB.name}} <span v-if="pay_mode == 1">{{$t('table.t19')}}：{{goods.custom && goods.custom.packageFB.times ? goods.custom.packageFB.times : '0'}}</span></el-checkbox>
								</el-checkbox-group>
							</div>
						</div>
					</div>
					<!-- <div class="package-radio" v-if="goods.id == 3">
						<el-radio v-model="packageValue" :disabled="type == 'update'" label="3" border>{{goods.name}}</el-radio>
					</div> -->
				</div>
			</el-form-item>
			
			<div class="footer-btn">
				<!-- <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">返回</el-button> -->
				<el-button @click="next" class="next qjc-fts-16 qjc-block">{{$t('open.t13')}}</el-button>
			</div>
			
		</el-form>
	</div>
</template>

<script>
	import { isPhone } from '@/utils'
	import { getPackage, enterpriseList, getUserGoodsList } from '@/utils/axios'
	
	export default{
		name: 'personal',
		data() {
			// 验证
			// 手机号
			var mobileVerify = (rule, value, callback) => {
				if(this.formData.yyuserid){
					if(value == ''){
						callback();
					}else if(isPhone(value)){
						callback();
					}else{
						callback(new Error('Incorrectly formatted mobile phone number'));
					}
				}else{
					if(value == ''){
						callback(new Error(this.$t('open.f27')));
					}else if(isPhone(value)){
						callback();
					}else{
						callback(new Error('Incorrectly formatted mobile phone number'));
					}
				}
			}
			
			// 企业用户id验证
			var yyuseridVerify = (rule, value, callback) => {
				if(this.formData.mobile){
					callback();
				}else{
					if(value == ''){
						callback(new Error(this.$t('open.f27')));
					}else{
						callback();
					}
				}
			}
			
			// 出生日期验证
			var birthVerify = (rule, value, callback) => {
				let minNum = this.hasFb ? 40 : 20;
				let maxNum = this.hasFb ? 80 : 90;
				
				if(this.birthSel == '') {
					callback(new Error(this.$t('open.f29')));
				}
				if(this.packageValue == '5') {
					if(this.getAgeBirth(new Date(this.birthSel).getTime()) >= 20 && this.getAgeBirth(new Date(this.birthSel).getTime()) <= 90) {
						callback();
					} else {
						callback(new Error(this.$t('open.a1')));
					}
					
				} else if(this.packageValue == '6') {
					if(this.getAgeBirth(new Date(this.birthSel).getTime()) >= 40 && this.getAgeBirth(new Date(this.birthSel).getTime()) <= 80) {
						callback();
					} else {
						callback(new Error(this.$t('open.a2')));
					}
				} else if(this.packageValue != '5' && this.packageValue != '6' && (this.examdateSel != '' && this.birthSel != '')) {
					if(this.getAge(new Date(this.birthSel).getTime(), new Date(this.examdateSel).getTime()) >= minNum && this.getAge(new Date(this.birthSel).getTime(), new Date(this.examdateSel).getTime()) <= maxNum) {
						callback();
					} else {
						callback(new Error(this.hasFb ? this.$t('open.a2') : this.$t('open.a1')));
					}
				}
				// if(this.birthSel > this.limitTime(maxNum) && this.birthSel <= this.limitTime(minNum)){
				// 	callback();
				// }else{
				// 	callback(new Error(`大数据评估仅适用于年龄在${this.hasFb ? '40~80' : '20~90'}周岁之间人群!`));
				// }
			}
			
			// 体检日期验证
			var examdateVerify = (rule, value, callback) => {
				if(this.birthSel == '') {
					callback(new Error(this.$t('open.f29')));
				}
				if(this.examdateSel == '') {
					callback(new Error(this.$t('open.f30')));
				} else {
					let nowDate = new Date();
					let nowTime = new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth()+1) + '/' + nowDate.getDate()).getTime();// 当前日期时间戳
					let birthTime = new Date(this.birthSel).getTime()
					if(nowTime >= new Date(this.examdateSel).getTime() && birthTime < new Date(this.examdateSel).getTime() ){
						callback();
					}else{
						callback(new Error(this.$t('open.f31')));
					}
				}
				if(this.packageValue == '5' || this.packageValue == '6') {
					callback();
				}
				
				// if(this.examdateSel == '') {
				// 	callback(new Error('请选择体检日期'));
				// } else {
				// 	let nowDate = new Date();
				// 	let nowTime = new Date(nowDate.getFullYear() + '/' + (nowDate.getMonth()+1) + '/' + nowDate.getDate()).getTime();// 当前日期时间戳
				// 	if(nowTime < this.examdateSel){
				// 		callback(new Error('体检日期不能大于当前时间'));
				// 	}else{
				// 		callback();
				// 	}
				// }
			}
			
			// 基础套餐必选
			var examBasicVerify = (rule, value, callback) => {
				callback()
				// if(value.indexOf('base') != -1){
				// 	callback();
				// }else{
				// 	callback(new Error('基础体检套餐必选'));
				// }
			}
			
			return {
				mobileConfirm: '',// 手机号确认
				mobileConfirmErr: false,// 手机号确认错误消息显示
				
				yyuseridConfirm: '',// 企业用户id确认
				yyuseridConfirmErr: false,// 手机号确认错误消息显示
				
				rules: {
					mobile: [
						{ validator: mobileVerify, trigger: 'blur' }
					],
					yyuserid: [
						{ validator: yyuseridVerify, trigger: 'blur' }
					],
					// 性别:1男  2女
					sex: [
						{ required: true, message: this.$t('open.f49'), trigger: 'blur' }
					],
					birth: [
						{ required: true, message: this.$t('open.f29'), trigger: 'blur' },
						{ validator: birthVerify, trigger: 'change' }
					],
					examdate: [
						{ required: true, message: this.$t('open.f30'), trigger: 'blur' },
						{ validator: examdateVerify, trigger: 'change' }
					],
					examplace: [
						{ required: false, message: '请输入体检地点', trigger: 'blur' }
					],
					package: [
						{ validator: examBasicVerify, trigger: 'change' }
					],
				}, 
				
				count: {
					base: {
						times: 0//默认为0 
					},
					packageA: {
						times: 0//默认为0
					},
					packageB: {
						times: 0//默认为0
					},
					packageC: {
						times: 0//默认为0
					},
					packageD: {
						times: 0//默认为0
					}
				},//套餐剩余次数
				packageList: [],
				package_opt: '1',
				pay_mode: '1',
				// 体检日期不能选择今日以前
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				
				isShowPhone: false,
				companyList: [],
				packageValue: '',
				checkCustomList: [],
				corpname: '',
				goodsList: []
			}
		},
		computed: {
			birthSel: {// 出生日期展示
				get() {
					if(this.formData.birth){//不能为空
						// 时间戳妙转为毫秒
						return this.formData.birth * 1000;
					}else{
						return '';
					}
					
				},
				set(value) {
					// 毫秒转换为秒
					this.formData.birth = value/1000;
					
				}
			},
			examdateSel: {// 体检日期展示
				get() {
					if(this.formData.examdate){//不能为空
						// 时间戳妙转为毫秒
						return this.formData.examdate;
					}else{
						return '';
					}
				},
				set(value) {
					// 毫秒转换为秒
					this.formData.examdate = value;
				}
			}
		},
		props: ['type', 'formData', 'reportCode'],
		mounted() {
			setTimeout(() => {
				this.packageValue = this.formData.package
				if(this.formData.sex != '') {
					this.formData.sex = Number(this.formData.sex)
				}
			},500)
			
			getUserGoodsList().then((res) => {
				if(res.code == 200) {
					let data = JSON.parse(window.atob(res.data))
					this.pay_mode = data.pay_mode
					let list = this.$t('goodsList')
					for(let i = 0; i < data.goodsList.length; i++) {
						for(let j = 0; j < list.length; j++) {
							if(data.goodsList[i].goods_id == list[j].goods_id){
								data.goodsList[i].name = list[j].name
							}
							if(data.goodsList[i].type == 'M22' && list[j].type == 'M22') {
								data.goodsList[i].name = list[j].name
							}
						}
					}
					this.goodsList = data.goodsList
				}
			})
		},
		watch:{
			packageValue(newVal) {
				if(newVal == '2') {
					this.checkCustomList = ['base']
				} else {
					this.checkCustomList = []
				}
			},
		},
		methods: {
			check_num (){
				if(this.formData.yyuserid) {
					 this.formData.yyuserid = this.formData.yyuserid.replace(/[^\a-\z\A-\Z0-9]/g, '');
				}
			},
			getAge (birthday, examdate) {
				let num = this.hasFb ? (864000000 - 86400000) : ((864000000 / 2) - 86400000)
			    return Math.ceil(((examdate-birthday) - num)/31536000000) - 1;
			},
			handleBirth(val) {
				console.log(val)
			},
			handleExamdate(val) {
				console.log(val)
			},
			getAgeBirth (birthday) {
				let num = this.packageValue == '6' ? 864000000 : (864000000 / 2)
				let nowTime = new Date().getTime()
				return Math.ceil(((nowTime-birthday) - num)/31536000000) - 1;
			},
			// 下一步
			next() {
				if(this.packageValue == '1') {
					this.$emit('handleMuying', false)
					this.$emit('handleHasFb', false)
				}
				if(this.packageValue == '3') {
					this.$emit('handleHasFb', false)
					this.$emit('handleMuying', true)
					
				}
				if(this.packageValue == '2') {
					this.$emit('handleMuying', false)
					this.$emit('handleHasFb', this.checkCustomList.indexOf('packageFB') == -1 ? false : true)
					this.formData.custom = this.checkCustomList
				}
				this.formData.package = this.packageValue
				// 报告编码和输入框一致
				this.$refs.personal.validate((isSuc, err) => {
					// 验证通过且确认输入也通过
					if(isSuc){
						if(this.packageValue == '') {
							this.$myAlert({
								title: '请选择套餐',
								content: '请选择开通套餐'
							});
							return
						}
						if(this.packageValue == "3" && this.formData.sex != 2) {
							this.$myAlert({
								title: '温馨提示',
								content: '您当前选中的套餐为母婴套餐，性别必须为女性'
							});
							return
						}
						this.$emit('stopQuery');
						
						// 成功进入下一步
						this.$emit('checkTab', 'Questions');
					}
				});
			},
			
			handlePrev() {
				
			},
			
			// 手机号确认
			mobileConfirmVerify() {
				if(this.mobileConfirm != this.formData.mobile){
					this.mobileConfirmErr = true;
					return false;
				}else{
					this.mobileConfirmErr = false;
					return true;
				}
			},
			
			// 企业用户id确认
			yyuseridConfirmVerify() {
				if(this.yyuseridConfirm != this.formData.yyuserid){
					this.yyuseridConfirmErr = true;
					return false;
				}else{
					this.yyuseridConfirmErr = false;
					return true;
				}
			},
			
			// 距今year年的时间戳 参数:year:距今年份
			limitTime(year) {
				let nowDate = new Date();
				return new Date((nowDate.getFullYear() - year) +'/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate()).getTime();
			},
			
			// 手机号必须输入数字
			phoneMustNumber(value) {
				this.formData.mobile = value.replace(/\D/g,'');
				this.isShowPhone = true
				setTimeout(() => {
					this.isShowPhone = false
				}, 5000)
			},
			phoneConfirmMustNumber(value) {
				this.mobileConfirm = value.replace(/\D/g,'');
			}
		}
	}
</script>

<style scoped>
	.el-form >>> .el-form-item__label{
		display: block;
		line-height: 36px;
	}
	.el-form .el-input >>> input,
	.el-form >>> .el-input__inner{
		height: 36px;
		line-height: 16px;
		font-size: 14px;
	}
	.el-form >>> .el-form-item__content,
	.el-form >>> .el-input__icon{
		line-height: 36px;
	}
	.product .el-checkbox,
	.product >>> .el-checkbox__input{
		position: absolute;
		top: 0;
		left: 0;
	}
	.product >>> .el-checkbox__inner{
		width: 18px;
		height: 16px;
		border-radius: 6px 0 6px 0;
	}
	.product >>> .el-checkbox__inner::after{
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%)rotate(45deg)scaleY(0);
	}
	.product >>> .el-checkbox__input.is-checked .el-checkbox__inner::after{
		transform: translate(-50%,-50%)rotate(45deg)scaleY(1);
	}
	.product >>> .el-checkbox__input.is-checked .el-checkbox__inner, 
	.product >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner{
		border-color: #6681FA;
		background-color: #6681FA;
	}
	.packages >>> .el-checkbox__label{
		display: none;
	}
	.packages >>> .el-form-item__content{
		width: 100%;
	}
	.packages >>> .el-form-item__error{
		position: absolute;
		top: 28px;
		left: 200px;
	}
	.el-form >>> .el-checkbox__label {
		font-size: 14px;
	}
	
	.wid-100 >>> .el-form-item__content {
		flex: 1;
	}
	
	.el-form >>> .is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label {
		color: #6883FB;
	}
	.el-form >>> .is-checked .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
		border-color: #6681FA;
		background-color: #6681FA;
	}
	.el-form >>> .el-radio.is-bordered.is-checked {
		border-color: #6681FA
	}
	.el-form >>> .is-checked .el-radio__input.is-disabled+span.el-radio__label {
		color: #6681FA;
	}
	.el-form >>> .is-checked .el-radio__input.is-disabled .el-radio__inner, .el-radio__input.is-disabled.is-checked .el-radio__inner {
		background-color: #6681FA;
	}
</style>
<style lang="scss" scoped>
	.personal{
		padding-top: 50px;
		
		.open-item-tip {
				position: absolute;
				top: -40px;
				left: 0px;
				width: 438px;
				height: 40px;
				padding: 0 16px;
				line-height: 40px;
				font-size: 18px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #293033;
				background-color: #FFFFFF;
				box-shadow: 0px 1px 4px 0px #D7E0ED;
				border-radius: 2px;
			}
		
		.insert-title {
			position: relative;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #293033;
			line-height: 16px;
			padding-bottom: 15px;
			border-bottom: 1px solid #E5E8F5;
			margin-bottom: 32px;
			
			&::before{
				position: absolute;
				left: -24px;
				top: 0;
				content: '';
				width: 4px;
				height: 20px;
				background-color: #6883FB;
			}
		}
		
		.el-form{
			.el-form-item{
				width: 50%;
				margin-right: 0;
				margin-bottom: 12px;
				
				.label{
					display: flex;
					// justify-content: flex-end;
					color: #777F8F;
				}
				.el-input,
				.el-select{
					// width: 420px;
					width: 500px;
				}
				.tip{
					line-height: 12px;
					margin-top: 6px;
					color: #ACAEB3;
				}
				.product{
					width: 193px;
					height: 56px;
					line-height: 18px;
					border-radius: 6px 0 6px 0;
					cursor: pointer; 
					
					.name{
						top: 50%;
						left: 50%;
						width: 100%;
						transform: translate(-50%,-50%);
					}
					
					&.basic{
						color: #40444D;
						background-color: #F0F4F7;
					}
					&.PLM{
						color: #C07E37;
						background-color: #FCF9F0;
					}
					&.comorbidity{
						color: #C92B5C;
						background-color: #FCF5F2;
					}
					&.illness{
						color: #5843BE;
						background-color: #F6F5FC;
					}
					&.health{
						color: #1955AF;
						background-color: #F0F6FC;
					}
				}
				p.base-count{
					width: 193px;
					margin-bottom: 16px;
				}
				.count{
					font-size: 13px;
					line-height: 13px;
					color: #777F8F;
					margin-top: 6px;
				}
			}
			.packages{
				width: 100%;
				margin: 16px 0 0;
				
				.el-checkbox-group{
					display: flex;
					flex-wrap: wrap;
					
					div.qjc-fl{
						margin-right: 16px;
					}
					
					.c-danger{
						color: #FF4C52;
					}
				}
			}
			h3{
				margin: 40px 0 24px;
			}
			.footer-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 40px;
			}
			.next{
				padding: 0 10px;
				// width: 96px;
				height: 34px;
				border-radius: 17px;
				border: none;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 400;
				color: #fff;
			}
			.prev {
				// width: 96px;
				padding: 0 10px;
				height: 34px;
				border-radius: 17px;
				border: 1px solid #6883FB;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #6883FB;
				background-color: #fff;
				margin-right: 24px;
			}
		}
	}
	
	.package-radio {
		margin-bottom: 12px;
	}
	.wid-100 {
		display: flex;
		width: 100% !important;
	}
	
	.package {
		
	}
	
	.package-radio {
		// width: 420px;
		width: 500px;
		
		.el-radio.is-bordered {
			// width: 420px;
			width: 500px;
		}
	}
	
	.custom-box {
		width: 100%;
		padding: 12px;
		border: 1px solid #DBDDE6;
	}
	
	.wid-100 .el-form-item__content {
		flex: 1;
	}
	.pd_l {
		padding-left: 20px; 
	}
</style>
